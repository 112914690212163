import styled from "styled-components";
import wave from "../../assets/svg/wave.svg";
import {
  mobileDevice,
  tabletDevice,
  largeDesktopDevice,
} from "../../style/constants";

export const Container = styled.div`
  display: flex;
  background-image: url(${wave});
  background-repeat: no-repeat;
  background-position: right -4%;
  background-size: contain;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;

  > h2 {
    margin: 100px 0;
    padding: 0 50px;
  }
  @media ${largeDesktopDevice} {
    background-position: right -23%;
  }

  @media ${tabletDevice} {
    background-position: right -2%;
  }
  @media ${mobileDevice} {
    background-position: right -1%;
  }
`;

export const Carousel = styled.div`
  display: flex;
  height: 180px;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 80px;
  margin-bottom: 80px;

  @media ${tabletDevice} {
    height: 120px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media ${mobileDevice} {
    height: 120px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const Wrapper = styled.div`
  animation: scrolling 40s linear infinite;
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  gap: 80px;

  @media ${mobileDevice} {
    animation: scrollingTablet 40s linear infinite;
  }

  @media ${tabletDevice} {
    animation: scrollingTablet 40s linear infinite;
  }
  @keyframes scrolling {
    0% {
      transform: translateX(0vw);
    }
    100% {
      transform: translateX(
        calc(
          -${(props) => props.imagesLength * 25}vw - 80px * ${(props) => props.imagesLength}
        )
      );
    }
  }

  @keyframes scrollingTablet {
    0% {
      transform: translateX(0vw);
    }
    100% {
      transform: translateX(
        calc(
          -${(props) => props.imagesLength * 33}vw - 80px * ${(props) => props.imagesLength}
        )
      );
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 20vw;
  box-sizing: border-box;
  flex-shrink: 0;

  @media ${tabletDevice} {
    width: 33vw;
  }
`;

export const Image = styled.img`
  display: flex;
  object-fit: contain;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;
