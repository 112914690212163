import styled from "styled-components";
import { tabletDevice, mobileDevice } from "../../style/constants";
import wisetackBackground from "../../assets/img/wisetack-background.png";

export const BackgroundContainer = styled.div`
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat-y;
  background-color: ${(props) => props.theme.colors.blueDarkest};
  background-image: url(${wisetackBackground});
  height: 184px;
  width: 100%;

  @media ${tabletDevice} {
    height: 110px;
    background-size: 100% 110px;
  }

  @media ${mobileDevice} {
    height: 80px;
    background-size: 100% 80px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const TextContainer = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.secondaryMedium};
  font-size: 48px;
  margin: 10px;

  @media ${tabletDevice} {
    font-size: 36px;
  }

  @media ${mobileDevice} {
    font-size: 16px;
    margin: 0;
    width: 100%;
  }
`;

export const ImgLogo = styled.img`
  width: 297px;

  @media ${tabletDevice} {
    width: 220px;
  }

  @media ${mobileDevice} {
    width: 120px;
  }
`;

export const Button = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  display: flex;
  font-weight: bold;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  height: ${(props) => (props.size === "lg" ? "64.11px" : "48.06px")};
  justify-content: center;
  letter-spacing: 1.6px;
  line-height: 34px;
  margin-left: 40px;
  margin-top: ${(props) => (props.size === "lg" ? "47px" : "0px")};
  text-align: center;
  text-transform: uppercase;
  transition: all ease-in 0.2s;
  width: ${(props) => (props.size === "lg" ? "317.4px" : "231px")};

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
  }

  @media ${tabletDevice} {
    height: ${(props) => (props.size === "lg" ? "40px" : "48.06px")};
    margin-top: ${(props) => (props.size === "lg" ? "17px" : "0px")};
    width: ${(props) => (props.size === "lg" ? "198px" : "231px")};
  }
  @media ${mobileDevice} {
    font-size: 8px;
    line-height: 16px;
    width: 100%;
    font-weight: bold;
    height: 25px;
  }
`;
