import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../style/constants";

export const MainContainer = styled.div`
  height: 530px;
  width: 100%;
  display: flex;

  @media ${tabletDevice} {
    height: 320.19px;
  }

  @media ${mobileDevice} {
    flex-direction: column;
    height: 100%;
  }
`;

export const FirstPartContainer = styled.div`
  background-color: ${(props) => props.theme.colors.blueDark};
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mobileDevice} {
    width: 100%;
    order: 2;
    padding: 20px 0;
  }
`;

export const SecondPartContainer = styled.div`
  background-color: ${(props) => props.theme.colors.blue};
  height: 100%;
  width: 50%;
  padding: 60px 20px;
  box-sizing: border-box;
  display: flex;

  @media ${tabletDevice} {
    padding: 30px 40px;
  }

  @media ${mobileDevice} {
    padding: 20px 20px 0 20px;
    width: 100%;
    order: 1;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 20px;

      &:nth-child(2) {
        > div {
          &:nth-child(n + 1) {
            text-align: center;
          }
        }
      }
    }
  }
`;

export const LogoImage = styled.div`
  height: 260px;
  width: 266px;

  > img {
    width: 100%;
    height: 100%;
  }

  @media ${tabletDevice} {
    height: 156.66px;
    width: 160.36px;
  }

  @media ${mobileDevice} {
    width: 89px;
    height: 91px;
  }
`;

export const FirstColumnInfo = styled.div`
  margin-right: 100px;
  display: flex;
  flex-direction: column;

  @media ${tabletDevice} {
    margin-right: 50px;
  }

  @media ${mobileDevice} {
    flex-direction: row;
    margin-right: 0;
  }
`;

export const SectionsInfo = styled.div`
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 25px;

  span {
    font-family: ${(props) => props.theme.fontFamily.secondary};
  }

  > h3 {
    text-transform: uppercase;
    font-size: 20px;
    font-family: ${(props) => props.theme.fontFamily.secondary};
    font-weight: bold;
    margin: 0;
  }

  p,
  a {
    margin: 0;
    font-size: 20px;
    font-family: ${(props) => props.theme.fontFamily.secondary};
    text-decoration: none;
    color: ${(props) => props.theme.colors.white};
  }

  > a > img {
    margin: 20px 20px 0 0;
  }

  @media ${tabletDevice} {
    margin-bottom: 8px;

    h3 {
      font-size: 18px;
    }

    p,
    a {
      font-size: 12px;
    }

    > a > img {
      margin: 10px 10px 0 0;
      width: 23x;
      height: 22px;
    }
  }

  @media ${mobileDevice} {
    > a > img {
      margin: 0 5px;
      width: 23x;
      height: 22px;
    }
  }
`;

export const BottomSection = styled.div`
  height: 45px;
  background-color: ${(props) => props.theme.colors.blueLighter};
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamily.secondarySemiBold};
  font-size: 13px;
  width: 100%;
  box-sizing: border-box;

  div {
    color: ${(props) => props.theme.colors.blueDarkest};
    margin: 0 40px;
    width: 50%;
    display: flex;
    justify-content: center;

    a {
      color: ${(props) => props.theme.colors.blueDarkest};
      text-decoration: none;
      margin-left: 10px;
    }

    span {
      margin: 10px;
    }
  }

  @media ${tabletDevice} {
    div {
      margin: 0 20px;
    }
  }

  @media ${mobileDevice} {
    font-size: 8px;
    height: 26px;

    div {
      margin: 0;
    }
  }
`;
