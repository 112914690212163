import React from "react";
import { Router } from "@reach/router";
import { Header, Footer } from "../components";

import { Landing } from "./Landing";
import { AboutUs } from "./AboutUs";

const Routes = () => {
  return (
    <>
      <Header/>
      <Router>
        <Landing path="/"/>
        <AboutUs path="about-us" />
      </Router>
      <Footer />
    </>
  );
};

export { Routes };
export default Routes;
