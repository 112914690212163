import Component from "../";
import { Label } from "../styled";
import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../../style/constants";

const LightButton = styled(Component)`
  background-color: ${(props) => props.theme.colors.blue};
  padding: 9px 0;
  width: 275.52px;

  :hover {
    background-color: ${(props) => props.theme.colors.blueDark};
  }

  ${Label} {
    color: ${(props) => props.theme.colors.white};
    font-size: 28px;

    @media ${tabletDevice} {
      font-size: 21px;
    }

    @media ${mobileDevice} {
      width: 275.52px;
      color: ${(props) => props.theme.colors.white};
      font-size: 12px;
    }
  }

  @media ${tabletDevice} {
    height: 37px;
    padding: 0 10px;
    width: 184px;
  }

  @media ${mobileDevice} {
    background-color: ${(props) => props.theme.colors.blueLight};
    height: 22px;
    padding: 0 5px;
    width: 120px;

    img {
      filter: invert(0) brightness(0) invert(1);
    }
  }
`;

export default LightButton;
