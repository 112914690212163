import React, { useState } from "react";
import TitleSection from "../TitleSection";
import { Container, Carousel, Wrapper, Image, ImageWrapper } from "./styled";
import {
  american,
  bryant,
  carrier,
  first,
  goodman,
  lennox,
  pnp,
  reem,
  ruud,
  trane,
  westinghouse,
  york,
} from "../../assets/img/companies";
import { ResponsiveContainer } from "../Layout";

const images = [
  american,
  bryant,
  carrier,
  first,
  goodman,
  lennox,
  pnp,
  reem,
  ruud,
  trane,
  westinghouse,
  york,
];
function Component(props) {
  const [imagesToShow, setImagesToShow] = useState(images);
  const imagesToRender = imagesToShow.map((image, index) => (
    <ImageWrapper>
      <Image
        src={image}
        key={`${index}-image`}
        data-testid="partnersModule-brands"
      />
    </ImageWrapper>
  ));
  return (
    <Container>
      <ResponsiveContainer>
        <TitleSection data-testid="partnersModule-title">
          SOME OF <br /> OUR PARTNERS
        </TitleSection>
      </ResponsiveContainer>
      <Carousel>
        <Wrapper imagesLength={imagesToShow.length}>
          {imagesToRender}
          {imagesToRender.map((e) => e).slice(0, 4)}
        </Wrapper>
      </Carousel>
    </Container>
  );
}

Component.propTypes = {};

export default Component;
export { Component as PartnersModule };
