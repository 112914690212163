import { TitleSection } from "./TitleSection";
import { CardService } from "./CardService";
import { ServicesModule } from "./ServicesModule";
import { WisetackModule } from "./WisetackModule";
import { Button } from "./Button/";
import { HeroImage } from "./HeroImage/";
import { ChooseSection } from "./ChooseSection";
import { GetQuotationModule } from "./GetQuotationModule";
import { RepairModule } from "./RepairModule";
import { Header } from "./Header";
import { ContactModule } from "./ContactModule";
import { LightButton } from "./Button/variants";
import { CustomInput } from "./Form/CustomInput";
import { CustomTextArea } from "./Form/CustomTextArea";
import { PartnersModule } from "./PartnersModule";
import { Footer } from "./Footer";
import { AboutUs } from "./AboutUs";
import { Testimonials } from "./Testimonials";

export {
  TitleSection,
  CardService,
  ServicesModule,
  WisetackModule,
  HeroImage,
  Button,
  ChooseSection,
  GetQuotationModule,
  PartnersModule,
  Header,
  RepairModule,
  Footer,
  ContactModule,
  LightButton,
  CustomInput,
  CustomTextArea,
  AboutUs,
  Testimonials,
};
