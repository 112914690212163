import React from "react";
import { Button } from "../index";
import {
  Background,
  Container,
  Title,
  Content,
  TextContent,
  FinanceButton,
} from "./styled";
import { ResponsiveContainer } from "../Layout";
// import { scrollToElementById } from "../../utils/scroll";

const Component = () => {
  // const onClick = () => {
  //   scrollToElementById("contact");
  // };

  return (
    <Background>
      <ResponsiveContainer>
        <Container>
          <Content>
            <Title data-testid="title">Arctic Wolf Air</Title>
            <TextContent data-testid="content">
              Experience the ultimate comfort in Arizona's scorching heat with
              Arctic Wolf Air. Your trusted HVAC partner, providing reliable and
              efficient cooling solutions for homes and businesses. Don't let
              the heat get you down, let us help you stay cool!
            </TextContent>
            <Button text={"602-615-3488"} size="lg" callNumber={true} />
          </Content>
        </Container>
      </ResponsiveContainer>
    </Background>
  );
};

export { Component as HeroImage };
export default Component;
