import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../style/constants";

export const ContainerAboutUs = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 37.20%, rgba(154, 248, 255, 0.3) 37.21%, rgba(14, 108, 215, 0.3) 100%);
  overflow: hidden;
  position: relative;
  @media ${tabletDevice} {
    background: linear-gradient(180deg,#FFFFFF 0%,#FFFFFF 74.90%,rgba(154,248,255,0.3) 74.91%,rgba(14,108,215,0.3) 100%);
    }

    @media ${mobileDevice} {
      background: linear-gradient(180deg,#FFFFFF 0%,#FFFFFF 43.90%,rgba(154,248,255,0.3) 43.91%,rgba(14,108,215,0.3) 100%);
    }
`;



export const IcebergContainer = styled.div`
    position: absolute;
    width: 1900px;
    left: -500px;
    top: -382px;
    overflow: hidden;

  
  @media ${tabletDevice} {
    width: 1200px;
    top: 280px;
    left: -300px;
    }

    @media ${mobileDevice} {
      width: 900px;
      top: 66px;
      left: -353px;
    }
`;

export const Iceberg = styled.img`
  width: 100%;
  height: 100%;
`;

export const TitleAboutUs = styled.h2`
  width: 100%;
  font-family: ${(props) => props.theme.fontFamily.secondarySemiBold};
  color: ${(props) => props.theme.colors.blueDarkest};
  font-size: 60px;
  white-space: pre-wrap;
  line-height: 60px;
  text-align: center;
  margin: 18% 0px 10%;

  @media ${tabletDevice} {
       font-size: 50px;
       margin: 20% 0px 6%;
    }

    @media ${mobileDevice} {
       font-size: 45px;
       margin: 25% 0px 7%;
    }
`;

export const ImageAboutUs = styled.div`
  width: 100%;
  height: auto;
  max-height: 441px;
  box-shadow: 0px 4px 24px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-bottom: 38px;
  > img {
    width: 100%;
    height: 100%;
  }

  @media ${tabletDevice} {
    margin-bottom: 30px;
    }
`;

export const DescriptionImageAboutUs = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  color: ${(props) => props.theme.colors.blueDarkest};
  font-size: 25px;
  line-height: 30px;
  white-space: pre-wrap;
  margin: 0 0 14%;

  @media ${tabletDevice} {
       font-size: 20px;
       margin: 0 0 6%;
    }

    @media ${mobileDevice} {
       font-size: 15px;
    }
`;

export const ContainerDescription = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 0% 3%;
`;

export const ContainerSectionsAboutUs = styled.div`
  margin-bottom: 150px;
`;

export const ContainerMainAboutUs = styled.div`
  max-width: 918px;
  margin: 0 auto;
  margin-bottom: 245px;
  position: relative;

  @media ${tabletDevice} {
    max-width: 592px;
    margin-bottom: 171px;
    }

    @media ${mobileDevice} {
    max-width: 480px;
    margin-bottom: 156px;
    }
`;
