import React from "react";
import {
  MainContainer,
  MainLabel,
  SecondaryLabel,
  PhoneLabel,
  PhoneIcon,
} from "./styled";
import PhoneSVG from "../../assets/svg/phone.svg";
import { ResponsiveContainer } from "../Layout";
import PhoneNumberButton from "../PhoneNumberButton";

const RepairModule = () => {
  return (
    <MainContainer>
      <ResponsiveContainer>
        <div>
          <MainLabel data-testid="title-section-id">
            REPAIR & MAINTENANCE
          </MainLabel>
          <SecondaryLabel data-testid="subtitle-section-id">
            COMMERCIAL AND RESIDENTIAL
          </SecondaryLabel>
        </div>
        <div>
          <PhoneNumberButton />
        </div>
      </ResponsiveContainer>
    </MainContainer>
  );
};

export { RepairModule };
export default RepairModule;
