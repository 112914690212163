import styled from "styled-components";
import arrow from "../../../../assets/img/arrow.png";
import quoteMark from "../../../../assets/img/quote-mark.png";
import arrowTablet from "../../../../assets/img/arrow-tablet.png";
import { mobileDevice, tabletDevice } from "../../../../style/constants";

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 371px;
  justify-content: center;
  margin: 60px 0 100px;
  width: 100%;
  
  @media ${tabletDevice} {
    margin: 0 0 20px;
  }
`;

const Navigation = styled.div`
  background-image: url(${arrow});
  background-repeat: no-repeat;
  cursor: pointer;
  height: 46px;
  transform: ${(props) => (props.right ? "rotate(180deg)" : 0)};
  width: 65px;
  
  @media ${tabletDevice} {
    background-image: url(${arrowTablet});
    height: 25px;
    width: 22px;
  }
`;

const List = styled.div`
  display: flex;
  margin: 0;
  overflow: hidden;
  scroll-behavior: smooth;
  width: 100%;
`;

const Testimonial = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  justify-content: center;
`;

const ImageContainer = styled.div``;

const Image = styled.img`
  background-image: url(img);

  @media ${tabletDevice} { 
    height: 84px;
    width: 84px;
  }
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.blueDarkest};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  padding: 20px 60px 10px 60px;
  position: relative;
  text-align: center;
  
  @media ${tabletDevice} { 
    font-size: 26px;
    padding: 10px 30px 10px 30px;
  }

  @media ${mobileDevice} {
    font-size: 20px;
  }
  
  &::before {
    background-image: url(${quoteMark});
    background-repeat: no-repeat;
    content: "\n";
    height: 38px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;

    @media ${tabletDevice} {
      background-position: center;
      background-size: contain;
      height: 20px;
      width: 20px;
    }
  }

  &::after {
    background-image: url(${quoteMark});
    background-repeat: no-repeat;
    bottom: 0;
    content: "\n";
    height: 38px;
    position: absolute;
    right: 0;
    transform: rotate(180deg);
    width: 30px;

    @media ${tabletDevice} {
      background-position: center;
      background-size: contain;
      height: 20px;
      width: 20px;
    }
  }
`;

const Name = styled.h5`
  color: ${(props) => props.theme.colors.blueDarkest};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 52px;

  @media ${tabletDevice} { 
    font-size: 25px;
    margin-top: 0;
  }

  @media ${mobileDevice} {
    font-size: 22px;
  }
`;

export {
  Container,
  Navigation,
  List,
  Testimonial,
  ImageContainer,
  Image,
  Text,
  Name,
};
