import { Link } from "@reach/router";
import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../style/constants";

const Container = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.blueDarkest};
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 93px;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 80%;
  width: 1214px;
  padding: 10px 20px;
  
  @media ${tabletDevice} {
    height: 32px;
    width: 680px;
    padding: 20px;
  }
  
  @media ${mobileDevice} {
    max-width: 100%;
    padding: 0 10px;
    width: calc(99% - 20px);
    height: 40px;
    padding: 10px;
  }
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.grayLight};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 55%;

  @media ${tabletDevice} {
    font-size: 13px;
    line-height: 15px;
  }

  @media ${mobileDevice} {
    font-size: 11px;
  }
`;

const Button = styled(Link)`
  background: ${(props) => props.theme.colors.white};
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${(props) => props.theme.colors.blueDarkest};
  font-family: ${(props) => props.theme.fontFamily.secondaryBold};
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px;
  padding: 10px 13px;
  text-align: right;
  text-decoration: none;

  @media ${tabletDevice} {
    font-size: 13px;
    padding: 2px;
    text-align: center;
    width: 137px;
  }

  @media ${mobileDevice} {
    font-size: 9px;
    width: 97px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export { Container, Text, Button };
