import React from "react";
import PropTypes from "prop-types";
import { TitleSection } from "./styled";
const Component = (props) => {
  const { children } = props;

  return <TitleSection className="title-section">{children}</TitleSection>;
};

Component.propTypes = {
  children: PropTypes.string,
};

export { Component as TitleSection };
export default TitleSection;
