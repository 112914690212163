import React from "react";
import {
  BackgroundContainer,
  Container,
  TextContainer,
  ImgLogo,
  Button,
} from "./styled";
import { ResponsiveContainer } from "../Layout";
import { navigate } from "@reach/router";

import wisetackLogo from "../../assets/img/wisetack-title.png";

const WisetackModule = () => {
  const onClick = () => {
    navigate("https://wisetack.us/#/phfagyp/prequalify");
  };
  return (
    <BackgroundContainer>
      <ResponsiveContainer>
        <Container>
          <TextContainer>Finance with</TextContainer>
          <ImgLogo src={wisetackLogo} alt={"Wisetack"} title={"Wisetack"} />
          <Button data-testid="finance-button" onClick={onClick}>
            Finance Now
          </Button>
        </Container>
      </ResponsiveContainer>
    </BackgroundContainer>
  );
};

export { WisetackModule };
export default WisetackModule;
