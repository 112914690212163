import React from "react";
import { Container, WrapperCards, Wrapper } from "./styled";
import { CardService, TitleSection } from "../../components";
import { ResponsiveContainer } from "../Layout";

const Component = () => {
  return (
    <>
      <Wrapper id="services">
        <ResponsiveContainer>
          <Container>
            <TitleSection>OUR SERVICES</TitleSection>
            <WrapperCards>
              <CardService
                title={"HVAC Services"}
                subtitle={"Stay Cool and Comfortable"}
                description={
                  "Our expert technicians provide installation, repair, and maintenance for all major HVAC systems. We'll ensure your home or business stays comfortable and energy-efficient throughout the year."
                }
              />

              <CardService
                title={"Refrigeration Services"}
                subtitle={"Keep it Fresh and Cold"}
                description={
                  "Arctic Wolf Air offers professional refrigeration services for commercial clients. Our certified technicians handle installation, repair, and maintenance of walk-in coolers, freezers, and ice machines, ensuring your products stay fresh and safe."
                }
              />

              <CardService
                title={"Maintenance Programs"}
                subtitle={"Protect Your Investment"}
                description={
                  "Our comprehensive maintenance programs are designed to extend the life of your HVAC and refrigeration systems while reducing energy costs. With regular inspections and tune-ups, we'll keep your equipment running at peak performance."
                }
              />
            </WrapperCards>
          </Container>
        </ResponsiveContainer>
      </Wrapper>
    </>
  );
};

Component.propTypes = {};

export default Component;
export { Component as ServicesModule };
