import styled from "styled-components";
import alert from "../../../assets/svg/alert.svg";
import { mobileDevice, tabletDevice } from "../../../style/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;

  @media ${tabletDevice} {
    margin: 0;
  }
`;

const Label = styled.label`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 7px;

  @media ${tabletDevice} {
    font-size: 20px;
  }

  @media ${mobileDevice} {
    display: none;
  }
`;

const FakeInput = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.grayLight};
  border: ${(props) =>
    props.error ? `2px solid ${props.theme.colors.red}` : "none"};
  border-radius: 5px;
  display: flex;
  height: 34px;
  justify-content: space-between;
  padding: 3px 5px;

  @media ${tabletDevice} {
    height: 20px;
  }
`;

const Input = styled.input`
  background: ${(props) => props.theme.colors.grayLight};
  border: none;
  font-size: 18px;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  height: calc(100% - 6px);
  width: 90%;

  &:hover,
  &:focus-visible {
    outline: none;
  }

  ::-webkit-input-placeholder {
    opacity: 0;
  }
  :-moz-placeholder {
    opacity: 0;
  }
  ::-moz-placeholder {
    opacity: 0;
  }
  :-ms-input-placeholder {
    opacity: 0;
  }

  @media ${mobileDevice} {
    font-size: 8px;

    ::-webkit-input-placeholder {
      opacity: 1;
    }
    :-moz-placeholder {
      opacity: 1;
    }
    ::-moz-placeholder {
      opacity: 1;
    }
    :-ms-input-placeholder {
      opacity: 1;
    }
  }
`;

const Icon = styled.div`
  background-image: url(${alert});
  background-repeat: no-repeat;
  background-size: contain;
  display: ${(props) => (props.error ? `block` : "none")};
  height: 20px;
  padding: 1px;
  width: 20px;
`;

const Extra = styled.div`
  align-items: start;
  display: flex;
  justify-content: ${(props) => (props.error ? "space-between" : "flex-end")};
`;

const ErrorMessage = styled.p`
  background-color: ${(props) =>
    props.error ? props.theme.colors.red : "none"};
  border-radius: 0 0 3px 3px;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 13px;
  margin: 0 0 0 9px;
  padding: 3px 5px;
  text-transform: uppercase;
  width: fit-content;

  @media ${tabletDevice} {
    font-size: 10px;
  }

  @media ${mobileDevice} {
    font-size: 8px;
  }
`;

const Help = styled.p`
  color: ${(props) => props.theme.colors.grayLight};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  margin: 5px 0 0;
  text-align: right;

  @media ${tabletDevice} {
    font-size: 10px;
  }

  @media ${mobileDevice} {
    font-size: 8px;
  }
`;

export { Container, Label, FakeInput, Input, Icon, Extra, ErrorMessage, Help };
