import React from "react";
import PropTypes from "prop-types";
import { Container, Title, Subtitle, Description } from "./styled";

const Component = (props) => {
  const { title, subtitle, description } = props;
  return (
    <Container data-testid="cardService">
      <Title data-testid="cardService-title">{title}</Title>
      <Subtitle data-testid="cardService-subtitle">{subtitle}</Subtitle>
      <Description data-testid="cardService-description">
        {description}
      </Description>
    </Container>
  );
};

Component.propTypes = {};

export default Component;
export { Component as CardService };
