import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../style/constants";

const Container = styled.div`
  height: 873px;
  width: 100%;

  > h2 {
    margin: 100px 0;
    padding: 0;
    width: auto;
  }

  @media ${tabletDevice} {
    height: 716px;
  }

  @media ${mobileDevice} {
    height: fit-content;
    padding-bottom: 20px;

    > h2 {
      margin: 50px 0;
    }
  }
`;

export { Container };
