import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body{
    margin: unset;
    background-color: #fff;
  }
`;

export default GlobalStyle;
