import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../style/constants";

export const Background = styled.div`
  background-color: ${(props) => props.theme.colors.blueDarkest};
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 184px;
  width: 100%;

  > div:first-of-type {
    margin-right: 5px;
  }

  > div:last-of-type {
    margin-left: 5px;
  }

  > div:first-of-type,
  > div:last-of-type {
    background-size: contain;
  }

  @media ${tabletDevice} {
    height: 110px;

    > div:first-of-type,
    > div:last-of-type {
      max-height: 72px;
      max-width: 126px;
    }
  }

  @media ${mobileDevice} {
    height: 120px;

    > div:first-of-type,
    > div:last-of-type {
      max-height: 53px;
      max-width: 73px;
    }
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  @media ${tabletDevice} {
    justify-content: flex-start;
  }
`;

const QuoteButton = styled.button`
  background: ${(props) => props.theme.colors.blueLight};
  border: none;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-family: "Jost";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: 53px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 32px;
  max-width: 499px;
  width: 100%;

  @media ${tabletDevice} {
    font-size: 1.25rem;
    height: 35px;
    line-height: 18px;
    max-width: 355px;
  }

  @media ${mobileDevice} {
    font-size: 12px;
    height: 29px;
    line-height: 15px;
    max-width: 337px;
  }
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 24px;

  @media ${tabletDevice} {
    font-size: 11px;
    margin-top: 5px;
    text-align: center;
    width: 60%;
  }
`;

export { Container, Content, QuoteButton, Text };
