export const theme = {
  fontFamily: {
    primary: "Roboto",
    primaryThin: '"Roboto-Thin"',
    primaryMedium: "Roboto-Medium",
    secondary: "Jost",
    secondaryMedium: "Jost-Medium",
    secondarySemiBold: "Jost-SemiBold",
    secondaryThin: "Jost-Thin",
    secondaryBold: "Jost-Bold",
  },
  colors: {
    blueDarken: "#081930",
    blueDark: "#122948",
    white: "#FFFFFF",
    blueDarkest: "#002B49",
    shadow: "rgba(0, 0, 0, 0.1)",
    blue: "#0E6CD7",
    blueLight: "#0e6cd7",
    grayLight: "#F5FEFF",
    red: "#C41E45",
    grayLight: "#F5FEFF",
    blueLighter: "#A8CEF1",
    black: "#000000",
  },
};
