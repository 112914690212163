import styled from "styled-components";
import { mobileDevice, mobile, desktop } from "../../style/constants";
import wolfImage from "../../assets/svg/wolf.svg";

export const ResponsiveContainer = styled.div`
  max-width: ${desktop}px;
  margin: 0 auto;
  width: 96%;
  height: inherit;
  padding: 0 2%;

  @media ${mobileDevice} {
    max-width: ${mobile}px;
    width: 90%;
    padding: 0 5%;
  }
`;

export const WolfImage = styled.div`
  background-image: url(${wolfImage});
  background-repeat: no-repeat;
  height: 120px;
  transform: ${(props) => (props.reverse ? "rotateZ(3.142rad)" : 0)};
  transform: ${(props) => (props.reverse ? "rotateY(3.142rad)" : 0)};
  width: 210px;
`;
