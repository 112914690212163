import React, { useEffect } from "react";

import {
  WisetackModule,
  ChooseSection,
  HeroImage,
  GetQuotationModule,
  ServicesModule,
  PartnersModule,
  RepairModule,
  ContactModule,
  Testimonials,
} from "../../components";
import { scrollToElementById } from "../../utils/scroll";

const Landing = (props) => {
  const { section } = props;

  useEffect(() => {
    if (section) {
      console.log({ section });
      scrollToElementById(section);
    }
  }, [section]);

  return (
    <>
      <HeroImage />
      <WisetackModule />
      <ServicesModule />
      <ChooseSection />
      <RepairModule />
      <PartnersModule />
      {/* <GetQuotationModule /> */}
      {/* <Testimonials /> */}
      <ContactModule />
    </>
  );
};

export { Landing };
export default Landing;
