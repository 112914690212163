import React from "react";
import { ContainerSection, TitleSection, DescriptionSection} from "./styled";

const SectionAboutUs = ({title, description}) => {
  return (
        
        <ContainerSection data-testid='section-about-us-container-id'>
            <TitleSection data-testid='title-container-section-id'>{title}</TitleSection>
            <DescriptionSection data-testid='description-container-section-id'> {description} </DescriptionSection>
        </ContainerSection>
  )
};

export { SectionAboutUs };
export default SectionAboutUs;