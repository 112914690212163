import React from "react";
import { Container, Text, Button } from "./styled";
import { scrollToElementById } from "../../utils/scroll";

const Component = () => {
  return (
    <Container>
      <Text data-testid="schedule-text">
        HVAC system not cooling or heating? Get in touch today for fast,
        dependable service.
      </Text>
      <Button
        to="/"
        data-testid="schedule-button"
        onClick={() => scrollToElementById("contact")}
      >
        SCHEDULE SERVICE
      </Button>
    </Container>
  );
};

export { Component as ScheduleMessage };
export default Component;
