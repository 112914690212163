import React from "react";

import {
  MainContainer,
  FirstPartContainer,
  SecondPartContainer,
  LogoImage,
  FirstColumnInfo,
  SectionsInfo,
  BottomSection,
} from "./styled";

import Logo from "../../assets/svg/logo_Arctic.svg";
import InstagramLogo from "../../assets/svg/instagram.svg";
import FacebookLogo from "../../assets/svg/facebook.svg";

const Component = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <MainContainer>
        <FirstPartContainer data-testid="half-container-id">
          <LogoImage data-testid="logo-container-id">
            <img src={Logo} data-testid="main-logo-id" />
          </LogoImage>
        </FirstPartContainer>
        <SecondPartContainer data-testid="half-container-id">
          <FirstColumnInfo>
            {/* <SectionsInfo data-testid="section-info-id">
              <h3>Services</h3>
              <p>HVAC installation, repair, and maintenance</p>
              <p>Commercial refrigeration services</p>
            </SectionsInfo>
            <SectionsInfo data-testid="section-info-id">
              <h3>Prices</h3>
              <p>Competitive pricing with transparent quotes</p>
              <p>Flexible financing options available</p>
            </SectionsInfo>
            <SectionsInfo data-testid="section-info-id">
              <h3>About Us</h3>
              <p>Locally owned and operated since 2010</p>
              <p>Dedicated to customer satisfaction</p>
            </SectionsInfo>
            <SectionsInfo data-testid="section-info-id">
              <h3>Contact</h3>
              <p>Phone: (602) 615-3488</p>
              <p>Email: info@Arcticwolfair.com</p>
            </SectionsInfo> */}
          </FirstColumnInfo>

          <div>
            <SectionsInfo data-testid="social-media-container-id">
              <a href="instagram.com/arcticwolfair" target="_blank">
                <img src={InstagramLogo} data-testid="instagram-logo-id" />
              </a>
              <a href="www.instagram/arcticwolf.com" target="_blank">
                <img src={FacebookLogo} data-testid="facebook-logo-id" />
              </a>
            </SectionsInfo>
            {/* <SectionsInfo data-testid="section-info-id">
              <p>Valley Wide in Arizona</p>
            </SectionsInfo> */}
            <SectionsInfo data-testid="section-info-id">
              <span>
                <a
                  href="tel:602-615-3488"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <span>602-615-3488</span>
                </a>
              </span>
            </SectionsInfo>
            <SectionsInfo data-testid="section-info-id">
              <a href="mailto:service@arcticwolfair.com">
                service@arcticwolfair.com
              </a>
            </SectionsInfo>
            {/* <SectionsInfo data-testid="section-info-id">
              <span>ROC #333235</span>
            </SectionsInfo> */}
          </div>
        </SecondPartContainer>
      </MainContainer>
      <BottomSection>
        <div>
          <div style={{ width: "100%" }}>
            <span>
              <a
                href="tel:602-615-3488"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <span>602-615-3488</span>
              </a>
            </span>
          </div>
        </div>
        {"|"}
        <div>
          <span>© {currentYear} Varo Air, Inc. - ROC #333235 </span>
        </div>
      </BottomSection>
    </div>
  );
};

export { Component as Footer };
export default Component;
