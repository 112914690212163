import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../style/constants";

export const ContainerSection = styled.div`
  width: 100%;
`;

export const TitleSection = styled.p`
  font-family: ${(props) => props.theme.fontFamily.secondarySemiBold};
  color: ${(props) => props.theme.colors.blueDarkest};
  font-size: 55px;
  white-space: pre-wrap;
  line-height: 30px;
  margin-bottom: 5%;

  @media ${tabletDevice} {
       font-size: 40px;
       margin-bottom: 4%;
    }
    @media ${mobileDevice} {
       font-size: 30px;
       margin-bottom: 4%;
    }
`;

export const DescriptionSection = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-weight: 500;
  color: ${(props) => props.theme.colors.blueDarkest};
  font-size: 24px;
  line-height: 30px;
  margin-block-start: 0px;
  margin-bottom: 58px;

  @media ${tabletDevice} {
       font-size: 20px;
       margin-bottom: 42px;
    }

    @media ${mobileDevice} {
       font-size: 17px;
       margin-bottom: 36px;
    }
`;
