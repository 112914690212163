import React from "react";
import SectionAboutUs from "../SectionsAboutUs";
import {
  ContainerAboutUs,
  TitleAboutUs,
  ImageAboutUs,
  DescriptionImageAboutUs,
  ContainerMainAboutUs,
  IcebergContainer,
  Iceberg,
} from "./styled";
import imageAboutUs from "../../assets/img/imageAboutUs.png";
import iceberg from "../../assets/svg/iceberg.svg";
import { ResponsiveContainer } from "../Layout";
import { ScheduleMessage } from "../ScheduleMessage";

const textSections = [
  {
    title: "Maintenance",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nisl, feugiat ut\nrisus in, malesuada varius tortor. Aenean non odio mollis, interdum diam vel,\nsollicitudin justo. Mauris ut risus leo",
  },
  {
    title: "Repair",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nisl, feugiat ut\nrisus in, malesuada varius tortor. Aenean non odio mollis, interdum diam vel,\nsollicitudin justo. Mauris ut risus leo",
  },
  {
    title: "A/C Replacement",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nisl, feugiat ut\nrisus in, malesuada varius tortor. Aenean non odio mollis, interdum diam vel,\nsollicitudin justo. Mauris ut risus leo",
  },
];

const AboutUs = () => {
  return (
    <ContainerAboutUs id="about-us" data-testid="section-about-us-id">
      <IcebergContainer>
        <Iceberg src={iceberg} />
      </IcebergContainer>
      <ResponsiveContainer>
        <ContainerMainAboutUs>
          <TitleAboutUs data-testid="title-section-about-us-id">
            About US
          </TitleAboutUs>
          <ImageAboutUs data-testid="img-section-about-us-id">
            <img src={imageAboutUs} />
          </ImageAboutUs>
          <DescriptionImageAboutUs data-testid="description-image-section-about-us">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat
            nisl, feugiat ut risus in, malesuada varius tortor. Aenean non odio
            mollis, interdum diam vel, sollicitudin justo. Mauris ut risus leo
          </DescriptionImageAboutUs>
          {textSections.map((txtSection, index) => (
            <SectionAboutUs
              key={index}
              title={txtSection.title}
              description={txtSection.description}
            />
          ))}
          <br />
          <br />
          <ScheduleMessage />
        </ContainerMainAboutUs>
      </ResponsiveContainer>
    </ContainerAboutUs>
  );
};

export { AboutUs };
export default AboutUs;
