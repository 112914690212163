import React from "react";
import ChooseCard from "../ChooseCard";
import {
  ContainerCards,
  ContainerContent,
  ContainerGridCards,
  TitleChooseSection,
} from "./styled";
import chooseCardIcon1 from "../../assets/img/choose-card-icon-1.png";
import chooseCardIcon2 from "../../assets/img/choose-card-icon-2.png";
import chooseCardIcon3 from "../../assets/img/choose-card-icon-3.png";
import chooseCardIcon4 from "../../assets/img/choose-card-icon-4.png";
import chooseCardIcon5 from "../../assets/img/choose-card-icon-5.png";
import chooseCardIcon6 from "../../assets/img/choose-card-icon-6.png";
import { ResponsiveContainer } from "../Layout";

const cards = [
  {
    title: "Fully Qualified and Insured",
    description:
      "We are driven by a team of highly qualified experts. We are fully insured to guarantee complete peace of mind.",
    img: chooseCardIcon1,
  },
  {
    title: "Free Advice\nand\nQuotations",
    description:
      "We offer free advice and quotations that would make it easier to decide and get the service that you actually need.",
    img: chooseCardIcon2,
  },
  {
    title: "All of Our Engineers are\nin-House",
    description:
      "We don’t rely on anyone else.\nWe have our own dedicated team of highly trained and experienced HVAC technicians.",
    img: chooseCardIcon3,
  },
  {
    title: "On Budget\nand\nOn Time",
    description:
      "We like to keep our word! We would give you an estimate and we would always give you the performance on time.",
    img: chooseCardIcon4,
  },
  {
    title: "Energy A+++ Rated\nEquipment",
    description:
      "We like to keep our word! We would give you an estimate and we would always give you the performance on time.",
    img: chooseCardIcon5,
  },
  {
    title: "Over\n15 Years\nof Experience",
    description:
      "We don’t rely on anyone else. We have our own dedicated team of highly trained and experienced HVAC technicians.",
    img: chooseCardIcon6,
  },
];

const ChooseSection = () => {
  return (
    <ContainerCards data-testid="section-choose-id">
      <ResponsiveContainer>
        <ContainerContent>
          <TitleChooseSection data-testid="title-sectionChoose-id">
            WHY CHOOSE US
          </TitleChooseSection>
          <ContainerGridCards>
            {cards.map((card, index) => (
              <ChooseCard
                key={index}
                title={card.title}
                description={card.description}
                img={card.img}
              />
            ))}
          </ContainerGridCards>
        </ContainerContent>
      </ResponsiveContainer>
    </ContainerCards>
  );
};

export { ChooseSection };
export default ChooseSection;
