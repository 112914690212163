import React, { useState } from "react";
import {
  FullWidthContainer,
  Container,
  Logo,
  CustomLink,
  MenuIcon,
  WrapperLinks,
  // ButtonActionContainer,
  // ButtonHeader,
  // ButtonHeaderMobile,
  FinanceButton,
} from "./styled";
import image from "../../assets/img/headerImage.png";
import menuIcon from "../../assets/svg/menu.svg";
import close from "../../assets/svg/close.svg";
import { ResponsiveContainer } from "../Layout";
import { useScroll } from "../../hooks/useScroll";
import { Link, navigate } from "@reach/router";
import { scrollToElementById } from "../../utils/scroll";

const Component = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { distanceY } = useScroll();
  const showActionButton = distanceY > 666;
  const activateGlass = distanceY > 110;
  const onFinanceNowClick = () => {
    navigate("https://wisetack.us/#/phfagyp/prequalify");
  };

  return (
    <FullWidthContainer activateGlass={activateGlass}>
      <ResponsiveContainer>
        <Container>
          <Link to="/">
            <Logo
              className={isModalOpen ? "open" : ""}
              src={image}
              alt={"Arctic Wolf Air Logo"}
              title={"Arctic Wolf Air Logo"}
            />
          </Link>
          <WrapperLinks className={isModalOpen ? "open" : ""}>
            <CustomLink
              to="/"
              data-testid="link"
              onClick={() => {
                scrollToElementById("services");
                setIsModalOpen((prev) => !prev);
              }}
            >
              SERVICES
            </CustomLink>
            {/* <CustomLink
              to="/"
              data-testid="link"
              onClick={() => setIsModalOpen((prev) => !prev)}
            >
              PRICES
            </CustomLink> */}
            {/* <CustomLink
              to="/about-us"
              data-testid="link"
              onClick={() => {
                scrollToElementById("about-us", "start");
                setIsModalOpen((prev) => !prev);
              }}
            >
              ABOUT US
            </CustomLink> */}
            <CustomLink
              to="/"
              data-testid="link"
              onClick={() => {
                scrollToElementById("contact");
                setIsModalOpen((prev) => !prev);
              }}
            >
              CONTACT
            </CustomLink>
            <CustomLink to="/" data-testid="link" onClick={onFinanceNowClick}>
              FINANCE NOW
            </CustomLink>

            {/* <PhoneNumberButton /> */}
            {/* <ButtonHeaderMobile
              text={"Get a quote"}
              onClick={() => {
                navigate("/");
                scrollToElementById("contact");
              }}
              size="md"
            /> */}
          </WrapperLinks>

          {/* <ButtonActionContainer size="md">
            <ButtonHeader
              showActionButton={showActionButton}
              text={"Get a quote"}
              onClick={() => {
                navigate("/");
                scrollToElementById("contact");
              }}
              size="md"
            />
          </ButtonActionContainer> */}

          {/* <ButtonActionContainer size="md">
            <ButtonHeader
              showActionButton={showActionButton}
              text={"Finance Now"}
              onClick={onFinanceNowClick}
              size="md"
            />
          </ButtonActionContainer> */}

          <MenuIcon
            src={isModalOpen ? close : menuIcon}
            onClick={() => setIsModalOpen((prev) => !prev)}
            className={isModalOpen ? "open" : ""}
          />
        </Container>
      </ResponsiveContainer>
    </FullWidthContainer>
  );
};

export { Component as Header };
export default Component;
