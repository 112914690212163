import React from "react";
import { PhoneLabel, PhoneIcon } from "./styled";
import PhoneSVG from "../../assets/svg/phone.svg";

const PhoneNumberButton = () => {
  return (
    <PhoneLabel data-testid="phone-section-id">
      <PhoneIcon data-testid="phone-icon-id">
        <img src={PhoneSVG} />
      </PhoneIcon>
      <span>
        <a
          href="tel:602-615-3488"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <span>602-615-3488</span>
        </a>
      </span>
    </PhoneLabel>
  );
};

export { PhoneNumberButton };
export default PhoneNumberButton;
