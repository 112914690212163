import { createGlobalStyle } from "styled-components";
import regularJostFont from "./Jost/Jost-Regular.ttf";
import mediumJostFont from "./Jost/Jost-Medium.ttf";
import semiBoldJostFont from "./Jost/Jost-SemiBold.ttf";
import thinRobotoFont from "./Roboto/Roboto-Thin.ttf";
import regularRobotoFont from "./Roboto/Roboto-Regular.ttf";
import mediumRobotoFont from "./Roboto/Roboto-Medium.ttf";
import thinJostFont from './Jost/Jost-Thin.ttf';
import boldJostFont from './Jost/Jost-Bold.ttf';

const FontsProvider = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    src: url(${regularRobotoFont}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto-Thin';
    src: url(${thinRobotoFont}) format('truetype');
  }

  @font-face {
    font-family: 'Jost';
    src: url(${regularJostFont}) format('truetype');
  }

  @font-face {
    font-family: 'Jost-Medium';
    src: url(${mediumJostFont}) format('truetype');
  }

  @font-face {
    font-family: 'Jost-SemiBold';
    src: url(${semiBoldJostFont}) format('truetype');
  }
  
  @font-face {
    font-family: 'Jost-Bold';
    src: url(${boldJostFont}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${regularRobotoFont}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto-Medium';
    src: url(${mediumRobotoFont}) format('truetype');
  }

  @font-face {
    font-family: 'Jost-Thin';
    src: url(${thinJostFont}) format('truetype');
  }
`;

export default FontsProvider;
