import styled from "styled-components";
import { tabletDevice } from "../../style/constants";

export const MainContainer = styled.div`
    width: 80%;
    height: 80%;
    padding: 10% 10%;
    background-color: ${props => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: start;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
`;
export const TittleCard = styled('p').attrs({testId:'title-card-id'})`
    max-width: 80%;
    font-family:${props => props.theme.fontFamily.secondarySemiBold};
    color:  ${props => props.theme.colors.blueDarkest};
    font-size: 28px;
    white-space: pre-wrap;
    line-height: 33px;

    @media ${tabletDevice} {
        font-size: 22px;
    }
`;

export const DescriptionCard = styled.p`
    max-width: 75%;
    font-family: ${props => props.theme.fontFamily.primary};
    font-weight: 400;
    color: ${props => props.theme.colors.blueDarkest};
    font-size: 22px;
    line-height: 30px;
    margin-block-start: 0px;
    padding-bottom: 20px;
    white-space: pre-wrap;

    @media ${tabletDevice} {
        font-size: 18px;
    }
`;

export const IconCard = styled.div`
    width: 64px;
    height: 64px;
    > img {
        width: 100%;
        height: 100%;
    }

    @media ${tabletDevice} {
        width: 50px;
        height: 50px;
    }
`;