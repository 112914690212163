import React from "react";
import { MainContainerFlex,MainContainer, TittleCard, DescriptionCard, IconCard } from "./styled";

const ChooseCard = ({title, description, img}) => {
  return (
        <MainContainer data-testid='choose-card-id'>
            <IconCard data-testid='img-card-id'>
                <img src={img}/>
            </IconCard>
            <TittleCard data-testid='title-card-id'>{title}</TittleCard>
            <DescriptionCard data-testid='description-card-id'> {description} </DescriptionCard>
        </MainContainer>
  )
};

export { ChooseCard };
export default ChooseCard;