import React from "react";
import { Background, Container, Content, QuoteButton, Text } from "./styled";
import { WolfImage } from "../Layout/";
import { ResponsiveContainer } from "../Layout";
import { scrollToElementById } from "../../utils/scroll";

const Component = () => {
  return (
    <Background>
      <ResponsiveContainer>
        <Container>
          <WolfImage />
          <Content>
            <QuoteButton
              data-testid="button"
              onClick={() => scrollToElementById("contact")}
            >
              get a free quote now!
            </QuoteButton>
            <Text data-testid="text">
              Get an exclusive view on our latest projects
            </Text>
          </Content>
          <WolfImage reverse />
        </Container>
      </ResponsiveContainer>
    </Background>
  );
};

export { Component as GetQuotationModule };
export default Component;
