import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../style/constants";
export const TitleSection = styled.h2`
  font-size: 60px;
  color: #002b49;
  font-family: ${(props) => props.theme.fontFamily.secondaryMedium};
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  margin-top: 40px;

  @media ${tabletDevice} {
    font-size: 50px;
  }

  @media ${mobileDevice} {
    font-size: 25px;
  }
`;
