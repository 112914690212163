import styled from "styled-components";
import pattern from "../../assets/img/pattern-huellas.png";
import { tabletDevice, mobileDevice } from "../../style/constants";

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  width: 100%;
`;

export const Divider = styled.div`
  background-color: ${(props) => props.theme.colors.blueDarkest};
  box-sizing: border-box;
  clip-path: polygon(0% 0%, 78% 40%, 77% 65%, 100% 76%, 100% 100%, 0 100%);
  margin-top: -115px;
  padding-top: 150px;
  width: 100%;

  @media ${tabletDevice} {
    clip-path: polygon(0% 0%, 74% 20%, 73% 44%, 100% 50%, 100% 100%, 0 100%);
    margin-top: -80px;
  }
  @media ${mobileDevice} {
    clip-path: polygon(0% 0%, 70% 16%, 67% 30%, 100% 36%, 100% 100%, 0 100%);
    margin-top: -60px;
  }
`;

export const Shape = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  clip-path: polygon(0% 18%, 75% 56%, 74% 83%, 100% 93%, 100% 100%, 0 100%);
  width: 100%;
  height: 150px;
  margin-top: -150px;

  @media ${tabletDevice} {
    clip-path: polygon(0% 17%, 70% 35%, 69% 58%, 100% 66%, 100% 100%, 0 100%);
  }

  @media ${mobileDevice} {
    clip-path: polygon(0% 13%, 61% 26%, 58% 43%, 100% 51%, 100% 100%, 0 100%);
  }
`;

export const Container = styled.div`
  background-image: url(${pattern});
  background-position: -60% 65%;
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 40px;
  width: 100%;
  z-index: 1;

  @media ${tabletDevice} {
    background-position: -120% bottom;
    padding: 0 0 40px;
  }

  @media ${mobileDevice} {
    background-position: -120% bottom;
    padding: 0 0 40px;
  }
`;

export const WrapperCards = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(330px, auto));
  justify-content: space-around;
  justify-items: center;
  position: relative;
  width: 100%;
  z-index: 1;

  @media ${tabletDevice} {
    grid-template-columns: repeat(auto-fit, minmax(345px, auto));
    justify-content: center;
  }

  @media ${mobileDevice} {
    justify-content: center;
  }
`;
