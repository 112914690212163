import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Label } from "./styled";

const Component = (props) => {
  const {
    text,
    onClick,
    className,
    size = "lg",
    image = "",
    callNumber = false,
  } = props;

  const renderImage = image && (
    <Icon>
      <img src={image} />
    </Icon>
  );

  const buttonContent = (
    <Button
      onClick={onClick}
      size={size}
      className={className}
      data-testid="button"
    >
      {renderImage}
      <Label size={size}>{text}</Label>
    </Button>
  );

  return callNumber ? (
    <a
      href={`tel:${text}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      {buttonContent}
    </a>
  ) : (
    buttonContent
  );
};

Component.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  image: PropTypes.string,
  size: PropTypes.oneOf(["lg", "md"]),
  callNumber: PropTypes.bool,
};

export { Component as Button };
export default Component;
