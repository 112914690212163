import React from "react";
import {
  Container,
  Label,
  FakeInput,
  Input,
  Icon,
  Extra,
  ErrorMessage,
  Help,
} from "./styled";

const Component = React.forwardRef((props, ref) => {
  const { text, name, error, help, testId, ...rest } = props;

  const capitalName = name.charAt(0).toUpperCase() + name.slice(1);

  const renderError = error && (
    <ErrorMessage error={error} data-testid="errorMessage">
      {error?.message}
    </ErrorMessage>
  );

  const renderHelp = help && <Help data-testid="help">{help}</Help>;

  return (
    <Container data-testid={testId}>
      <Label data-testid="title">{text}</Label>
      <FakeInput error={error} data-testid="fieldContainer">
        <Input ref={ref} name={name} placeholder={capitalName} {...rest} data-testid="field" />
        <Icon error={error} data-testid="icon" />
      </FakeInput>
      <Extra error={error}>
        {renderError}
        {renderHelp}
      </Extra>
    </Container>
  );
});

export { Component as CustomInput };
export default Component;
