import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../style/constants";

const Button = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.blue};
  border: none;
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  display: flex;
  font-weight: 600;
  height: ${(props) => (props.size === "lg" ? "64.11px" : "48.06px")};
  justify-content: center;
  letter-spacing: 1.6px;
  line-height: 34px;
  margin-top: ${(props) => (props.size === "lg" ? "47px" : "0px")};
  text-align: center;
  text-transform: uppercase;
  transition: all ease-in 0.2s;
  width: ${(props) => (props.size === "lg" ? "317.4px" : "231px")};

  &:hover {
    background-color: ${(props) => props.theme.colors.blueDark};
  }

  @media ${tabletDevice} {
    height: ${(props) => (props.size === "lg" ? "40px" : "48.06px")};
    margin-top: ${(props) => (props.size === "lg" ? "17px" : "0px")};
    width: ${(props) => (props.size === "lg" ? "198px" : "231px")};
  }

  @media ${mobileDevice} {
    height: ${(props) => (props.size === "lg" ? "24px" : "48.06px")};
    width: ${(props) => (props.size === "lg" ? "145px" : "48.06px")};
  }
`;

const Icon = styled.div`
  height: 23px;
  margin: 0 8px;
  width: 23px;

  > img {
    height: 100%;
    width: 100%;
  }
`;

const Label = styled.span`
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: ${(props) => (props.size === "lg" ? "32px" : "24px")};
  font-style: normal;

  @media ${tabletDevice} {
    font-size: ${(props) => (props.size === "lg" ? "22px" : "24px")};
  }

  @media ${mobileDevice} {
    font-size: ${(props) => (props.size === "lg" ? "12px" : "16px")};
  }
`;

export { Button, Icon, Label };
