import React from "react";
import * as yup from "yup";
import { WolfImage } from "../Layout/";
import { useForm } from "react-hook-form";
import PhoneSVG from "../../assets/svg/phoneWhite.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { LightButton, CustomInput, CustomTextArea } from "../";
import {
  Container,
  Content,
  Contact,
  Text,
  Form,
  Row,
  Submit,
  Background,
  WhiteBackground,
} from "./styled";
import { ResponsiveContainer } from "../Layout";
import PhoneNumberButton from "../PhoneNumberButton";

const schema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  message: yup.string().required(),
});

const defaultValue = {
  name: "",
  email: "",
  message: "",
};

const Component = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValue,
  });

  const phoneNumber = "602-615-3488";

  const onSubmit = (data) => {
    const { name, email, message } = data;
    const emailBody = `
      Hi, my name is ${name},
      %0D
      I would like to be contacted at ${email}
      %0D
      %0D
      ${message}
      %0D
      %0D
    `;

    window.open(
      `mailto:victor@jaquez.mesubject=${name} - ArcticWolf Contact Form&body=${emailBody}`
    );
  };

  const callPhoneNumber = () => {
    window.open(`tel:+${phoneNumber}`);
  };

  const errorElement = (message = "Fill this field") => (
    <FormError data-testid="error">{message}</FormError>
  );

  return (
    <Container id="contact">
      <WhiteBackground />
      <Background />
      <ResponsiveContainer>
        <Content>
          <Contact>
            <Text>CONTACT</Text>
            <WolfImage data-testid="wolf-image" />
            <PhoneNumberButton />
          </Contact>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <CustomInput
                text="Name"
                testId="name"
                error={errors.name}
                {...register("name")}
              />
              <CustomInput
                text="Email"
                testId="email"
                error={errors.email}
                {...register("email")}
                help="Type a valid email: yourname@example.com "
              />
            </Row>
            <CustomTextArea
              text="Message"
              testId="message"
              error={errors.message}
              {...register("message")}
            />
            <Submit type="submit" value="SUBMIT" data-testid="submit" />
          </Form>
        </Content>
      </ResponsiveContainer>
    </Container>
  );
};

export { Component as ContactModule };
export default Component;
