import React from "react";
import { Container } from "./styled";
import { TitleSection } from "../index";
import { Slider } from "./components/Slider";
import { ScheduleMessage } from "../ScheduleMessage";
import user1 from "../../assets/img/testimonials/user1.png";
import { ResponsiveContainer } from "../Layout";

export const testimonials = [
  {
    id: 1,
    image: user1,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nisl, feugiat ut risus in, malesuada varius tortor. ",
    name: "Lorem Impsun",
  },
  {
    id: 2,
    image: user1,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nisl, feugiat ut risus in, malesuada varius tortor. ",
    name: "waka",
  },
  {
    id: 3,
    image: user1,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nisl, feugiat ut risus in, malesuada varius tortor. ",
    name: "Tala",
  },
];

const Component = () => {
  return (
    <ResponsiveContainer>
      <Container>
        <TitleSection>TESTIMONIALS</TitleSection>
        <Slider testimonials={testimonials} />
        <ScheduleMessage />
      </Container>
    </ResponsiveContainer>
  );
};

export { Component as Testimonials };
export default Component;
