import styled from "styled-components";
import image from "../../assets/img/heroImage.png";
import { mobileDevice, tabletDevice } from "../../style/constants";

export const Background = styled.div`
  align-items: flex-end;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  height: 932px;
  width: 100%;

  @media ${tabletDevice} {
    background-position-x: 16%;
    height: 616px;
  }

  @media ${mobileDevice} {
    height: 447px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Content = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-top: 178px;
  width: 60%;

  @media ${tabletDevice} {
    padding-top: 130px;
    margin-right: 0px;
    width: 60%;
  }

  @media ${mobileDevice} {
    padding-top: 100px;
    margin-right: 0px;
    width: 60%;
  }
`;

const Title = styled.h2`
  color: ${(props) => props.theme.colors.blueDark};
  font-family: ${(props) => props.theme.fontFamily.secondaryMedium};
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  margin: 0 0 25px 0;
  text-align: right;
  text-transform: uppercase;

  @media ${tabletDevice} {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 8px;
  }

  @media ${mobileDevice} {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 0;
  }
`;

const SubTitle = styled.h3`
  color: ${(props) => props.theme.colors.blueDark};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  margin: 0 0 34px 0;
  text-align: right;
  text-transform: uppercase;

  @media ${tabletDevice} {
    font-size: 44px;
    line-height: 44px;
    margin-bottom: 18px;
  }

  @media ${mobileDevice} {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 15px;
  }
`;

const TextContent = styled.p`
  color: ${(props) => props.theme.colors.blueDark};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  margin: 0;
  text-align: right;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  @media ${tabletDevice} {
    font-size: 15px;
    line-height: 15px;
    width: 214px;
  }

  @media ${mobileDevice} {
    font-size: 15px;
    width: 210px;
  }
`;

export { Container, Title, SubTitle, Content, TextContent };
