import styled from "styled-components";
import backGround from "../../assets/img/choose-section-background.png";
import { tabletDevice, mobileDevice } from "../../style/constants";

export const ContainerCards = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${backGround});
  background-repeat: no-repeat;
  background-position: 3px -85px;
`;

export const ContainerContent = styled.div`
  width: 100%;
`;

export const TitleChooseSection = styled.h2`
  font-size: 60px;
  font-family: ${(props) => props.theme.fontFamily.secondaryMedium};
  color: ${(props) => props.theme.colors.blueDarkest};
  margin-top: 130px;
  margin-bottom: 80px;
  @media ${tabletDevice} {
    font-size: 50px;
  }
  @media ${mobileDevice} {
    margin-top: 10px;
    margin-bottom: 40px;
    font-size: 30px;
  }
`;

export const ContainerGridCards = styled.div`
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 32px;
  align-items: start;
  justify-items: center;
  margin-bottom: 220px;

  @media ${tabletDevice} {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 50px;
  }

  @media ${mobileDevice} {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 20px;
  }
`;
