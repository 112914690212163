import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../style/constants";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0px 8px 24px ${(props) => props.theme.colors.shadow};
  color: ${(props) => props.theme.colors.white};
  max-width: 350px;
  min-height: 375px;
  padding: 36px 40px;
  width: 100%;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.blue};
  font-family: ${(props) => props.theme.fontFamily.secondarySemiBold};
  font-size: 32px;
  margin: 0;
  text-transform: uppercase;
  width: 100%;

  @media ${mobileDevice} {
    font-size: 28px;
  }
`;

export const Subtitle = styled.p`
  color: ${(props) => props.theme.colors.blueDarkest};
  font-family: ${(props) => props.theme.fontFamily.secondarySemiBold};
  font-size: 19px;
  margin: 0;
  text-transform: uppercase;
  width: 100%;

  @media ${mobileDevice} {
    font-size: 16px;
  }
`;

export const Description = styled.p`
  color: ${(props) => props.theme.colors.blueDarkest};
  font-family: ${(props) => props.theme.fontFamily.primaryThin};
  font-size: 20px;
  line-height: 26px;
  width: 100%;
`;
