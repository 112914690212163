import React, { createRef } from "react";
import { useEffect } from "react";
import {
  Container,
  Navigation,
  List,
  Testimonial,
  ImageContainer,
  Image,
  Text,
  Name,
} from "./styled";

const Component = (props) => {
  const { testimonials } = props;
  const sliderRef = createRef(null);
  let sliderTimer = setTimeout(() => scroll(1), 6000);

  useEffect(() => {
    return () => {
      clearTimeout(sliderTimer);
    };
  }, []);

  const configSliderTimer = () => {
    clearTimeout(sliderTimer);
    sliderTimer = setTimeout(() => scroll(1), 6000);
  };

  const renderTestimonials =
    testimonials &&
    testimonials.map((item) => {
      const { id, image, text, name } = item;
      return (
        <Testimonial key={`testimonial-${id}`} data-testid="testimonial">
          <ImageContainer>
            <Image src={image} data-testid="testimonial-image" />
          </ImageContainer>
          <Text data-testid="testimonial-text">{text}</Text>
          <Name data-testid="testimonial-name">{name}</Name>
        </Testimonial>
      );
    });

  const scroll = (factor) => {
    const width = sliderRef?.current?.offsetWidth || 0;
    const currentScrollPosition = sliderRef?.current?.scrollLeft || 0;
    const maxWidth = sliderRef?.current?.scrollWidth || 0;

    let newPosition = width * factor;

    if (currentScrollPosition + width + 10 >= maxWidth && factor === 1) {
      newPosition = width - maxWidth;
    }

    if (currentScrollPosition === 0 && factor === -1) {
      newPosition = maxWidth - width;
    }

    sliderRef.current.scrollLeft += newPosition;
    configSliderTimer();
  };

  return (
    <Container>
      <Navigation right onClick={() => scroll(-1)} data-testid="navigator" />
      <List ref={sliderRef}>{renderTestimonials}</List>
      <Navigation onClick={() => scroll(1)} data-testid="navigator" />
    </Container>
  );
};

export { Component as Slider };
export default Component;
