import { useEffect, useState } from "react";

export const useScroll = () => {
  const [distanceY, setDistanceY] = useState(0);

  const getDistanceY = () => {
    setDistanceY(window.scrollY || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", getDistanceY);
    return () => {
      window.removeEventListener("scroll", getDistanceY);
    };
  }, []);

  return { distanceY };
};
