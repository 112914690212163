import styled from "styled-components";
import { mobileDevice, tabletDevice } from "../../style/constants";

import backgroundImage from "../../assets/img/all-type-service-banne.png";

export const MainContainer = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 371.44px;
  justify-content: space-between;
  padding: 35px 0;
  width: 100%;

  @media ${tabletDevice} {
    padding: 30px 50px;
    height: 221.65px;
    background-position: center;
    background-size: cover;
  }

  @media ${mobileDevice} {
    height: 163.93px;
    padding: 18px 25px;

    p {
      margin: 0;
    }
  }
`;

export const MainLabel = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.secondaryMedium};
  font-size: 48px;
  margin: 10px 0;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media ${tabletDevice} {
    margin: 5px 0;
    font-size: 30px;
  }
  @media ${mobileDevice} {
    font-size: 20px;
  }
`;

export const SecondaryLabel = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.secondaryMedium};
  font-size: 32px;
  margin: 30px 0;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media ${tabletDevice} {
    font-size: 20px;
    margin: 20px 0;
  }

  @media ${mobileDevice} {
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 25px;
  }
`;

export const PhoneLabel = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.grayLight};
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.blueDarkest};
  display: flex;
  height: 66px;
  justify-content: center;
  width: 306.83px;

  > span {
    font-family: ${(props) => props.theme.fontFamily.secondaryMedium};
    font-size: 32px;
  }

  @media ${tabletDevice} {
    width: 231px;
    height: 39px;
    border-radius: 8px;

    > span {
      font-size: 27px;
    }
  }

  @media ${mobileDevice} {
    width: 181px;
    height: 29px;

    > span {
      font-size: 20px;
    }
  }
`;

export const PhoneIcon = styled.div`
  height: 23px;
  margin: 0 8px;
  width: 23px;

  > img {
    height: 100%;
    width: 100%;
  }

  @media ${tabletDevice} {
    margin: 0;
  }

  @media ${mobileDevice} {
    margin: 0;
    width: 15px;
    height: 15px;
    margin: 0 5px;
  }
`;
