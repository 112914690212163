import styled from "styled-components";
import pattern from "../../assets/img/pattern-huellas.png";
import { mobileDevice, tabletDevice } from "../../style/constants";

const Container = styled.div`
  height: 617px;
  position: relative;
  width: 100%;
  overflow: hidden;

  @media ${tabletDevice} {
    height: 540px;
    position: relative;
  }

  @media ${mobileDevice} {
    height: 441px;
  }
`;

const Content = styled.div`
  display: flex;
  height: 541px;
  padding: 57px 0 0;
  position: relative;
  justify-content: space-around;
  > form {
    width: 68%;

    @media ${tabletDevice} {
      width: 90%;
    }

    @media ${mobileDevice} {
      width: 100%;
    }
  }

  > div:first-of-type {
    margin-right: 50px;

    @media ${tabletDevice} {
      margin-right: 0;
      width: 100%;
    }
  }

  @media ${tabletDevice} {
    align-items: center;
    min-height: 480px;
    flex-direction: column;
    padding: 20px 0;
    justify-content: unset;
  }

  @media ${mobileDevice} {
    height: 200px;
  }
`;

const Contact = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 34px;

  @media ${tabletDevice} {
    flex-direction: row;
    justify-content: space-between;

    > div:first-of-type {
      background-size: cover;
      height: 73px;
      order: 1;
      width: 128px;
    }

    > div:last-of-type {
      order: 2;
    }
  }

  @media ${mobileDevice} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: -10px;

    > div:first-of-type {
      background-size: cover;
      height: 30px;
      order: 1;
      width: 53px;
    }

    > div:last-of-type {
      order: 2;
    }
  }
`;

const Text = styled.h5`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 20px 0 16px 0;

  @media ${tabletDevice} {
    font-size: 30px;
    order: 0;
  }

  @media ${mobileDevice} {
    font-size: 18px;
  }
`;

const Form = styled.form`
  @media ${tabletDevice} {
    margin-top: 15px;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: 45%;
  }

  @media ${mobileDevice} {
    margin-bottom: 20px;
  }
`;

const Submit = styled.input`
  background: ${(props) => props.theme.colors.blueLight};
  border: none;
  border-radius: 100px;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: 30.24px;
  width: 136.24px;

  @media ${tabletDevice} {
    font-size: 17px;
    height: 24px;
    width: 127px;
  }

  @media ${mobileDevice} {
    font-size: 17px;
    width: 120px;
  }
`;

export const Background = styled.div`
  background-color: ${(props) => props.theme.colors.blueDarkest};
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
  width: 100%;
  height: 541px;
  position: absolute;
  top: 0;
  @media ${mobileDevice} {
    height: 441px;
  }
`;

const WhiteBackground = styled.div`
  background-image: url(${pattern});
  bottom: 0;
  background-position: 80% 35%;
  background-clip: border-box;
  height: 175px;
  overflow: hidden;
  position: absolute;
  width: 100%;

  @media ${tabletDevice} {
    background-position: 70% 25%;
    background-size: 702px;
    bottom: -46px;
    height: 175px;
  }
`;

export {
  Container,
  Content,
  Contact,
  Text,
  Form,
  Row,
  Submit,
  WhiteBackground,
};
