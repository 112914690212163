import styled from "styled-components";
import { Button } from "../Button";
import { Link } from "@reach/router";

const mobileDevice = `only screen and (max-width: 860px)`;
const tabletDevice = `only screen and (max-width: 1200px)`;

export const FullWidthContainer = styled.div`
  display: flex;
  min-height: 77px;
  width: 100%;
  max-width: 100%;
  position: fixed;
  z-index: 999;
  background-image: ${(props) => {
    const {
      activateGlass,
      theme: {
        colors: { blueDark },
      },
    } = props;

    const color = activateGlass
      ? `${blueDark} 45%, ${blueDark}E6 60%, ${blueDark}1A`
      : `${blueDark}E6, ${blueDark}00`;

    return `linear-gradient(to bottom, ${color})`;
  }};
`;

const Container = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 17px 0;
  @media ${tabletDevice} {
    padding: 17px 0 0;
  }

  @media ${mobileDevice} {
    box-sizing: border-box;
    justify-content: center;
    padding-top: 22px;
    height: 50px;
  }
`;

const Logo = styled.img`
  height: 46.04px;

  @media ${tabletDevice} {
    height: auto;
    width: 180px;
  }

  @media ${mobileDevice} {
    height: auto;
    width: 190px;
    z-index: 1;
  }
`;
const WrapperLinks = styled.ul`
  display: flex;
  gap: 50px;
  justify-content: space-between;

  @media ${tabletDevice} {
    gap: 25px;
  }
  @media ${mobileDevice} {
    display: inline-flex;
    background-color: ${(props) => props.theme.colors.white};
    gap: 40px;
    height: 300px;
    justify-content: flex-start;
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    top: -15px;
    padding: 110px 16px 100px;
    opacity: 0;
    background: linear-gradient(
      180deg,
      #122948 0%,
      ${(props) => props.theme.colors.white} 75px
    );
    transition: opacity 0.25s ease;
    pointer-events: none;
    &.open {
      display: inline-flex;
      opacity: 1;
      pointer-events: all;
    }
  }
`;
const CustomLink = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-transform: uppercase;
  text-decoration: none;

  @media ${mobileDevice} {
    display: list-item;
    list-style: none;
    color: ${(props) => props.theme.colors.blueDark};
    font-size: 30px;
    margin-left: 25px;
    width: fit-content;
    &::before {
      content: "■";
      color: ${(props) => props.theme.colors.blue};
      margin-right: 20px;
    }
    &.open {
      WrapperLinks {
        display: inline-flex;
        opacity: 1;
        pointer-events: all;
      }
    }
  }
`;

export const ButtonActionContainer = styled.div`
  width: 231px;
  @media ${mobileDevice} {
    width: 0;
  }
`;

const ButtonHeader = styled(Button)`
  display: ${(props) => (props.showActionButton ? "flex" : "none")};
  background-color: ${(props) => props.theme.colors.blue};
  :hover {
    background-color: ${(props) => props.theme.colors.blueDark};
  }
  @media ${mobileDevice} {
    display: none;
  }
  @media ${tabletDevice} {
    width: 100%;
    > span {
      font-size: ${(props) => (props.size === "lg" ? "32px" : "20px")};
    }
  }
`;

const ButtonHeaderMobile = styled(ButtonHeader)`
  display: none;
  @media ${mobileDevice} {
    border-radius: 0;
    display: flex;
    height: 80px;
    position: absolute;
    bottom: 0;
    width: 100%;

    > span {
      font-size: 30px;
    }
  }
`;

export const MenuIcon = styled.img`
  display: none;
  height: 30px;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;

  @media ${mobileDevice} {
    display: block;
    position: absolute;
    right: 20px;
  }
`;

const FinanceButton = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  display: flex;
  font-weight: bold;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  height: ${(props) => (props.size === "lg" ? "64.11px" : "48.06px")};
  justify-content: center;
  letter-spacing: 1.6px;
  line-height: 34px;
  margin-left: 40px;
  margin-top: ${(props) => (props.size === "lg" ? "47px" : "0px")};
  text-align: center;
  text-transform: uppercase;
  transition: all ease-in 0.2s;
  width: ${(props) => (props.size === "lg" ? "317.4px" : "231px")};

  &:hover {
    background-color: ${(props) => props.theme.colors.blueDark};
  }

  @media ${tabletDevice} {
    height: ${(props) => (props.size === "lg" ? "40px" : "48.06px")};
    margin-top: ${(props) => (props.size === "lg" ? "17px" : "0px")};
    width: ${(props) => (props.size === "lg" ? "198px" : "231px")};
  }
`;

export {
  Container,
  CustomLink,
  Logo,
  WrapperLinks,
  ButtonHeader,
  ButtonHeaderMobile,
  FinanceButton,
};
